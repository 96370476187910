import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { Auth0Provider } from "@auth0/auth0-react";
import { App } from "./App";
import { Provider } from "react-redux";
import "./index.css";
import { adminatorStore } from "./store/adminator-store";

createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <Auth0Provider
      domain="dev-3aakzf3wyufl10ot.eu.auth0.com"
      clientId="BzHPx09tX5WItNOMNlzCl0zjF3kJsAzC"
      authorizationParams={{
        redirect_uri: window.location.origin + "/dashboard",
        audience: "https://adminator/api",
        scope: "openid profile email phone",
      }}
    >
      <Provider store={adminatorStore}>
        <App />
      </Provider>
    </Auth0Provider>
  </StrictMode>
);
