import { SpecialZoomLevel, Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

export interface IProps {
  url: string;
}

export const PdfViewer = (props: IProps) => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin() as any;

  return (
    <Worker workerUrl="/pdf-worker.js">
      <Viewer
        
        fileUrl={props.url}
        defaultScale={SpecialZoomLevel.PageFit}
        enableSmoothScroll
        plugins={[
          // Register plugins
          defaultLayoutPluginInstance
        ]}
      />
    </Worker>
  );
};
