import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Divider,
  Grid2,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store/adminator-store";
import {
  CompanyDto,
  setSelectedUserCompany,
} from "../../store/slicers/user-settings.slicer";

import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import HelpIcon from "@mui/icons-material/Help";

import { httpClient } from "../../infrastructure/http-client/http-client";
import { setBreadcrumbs } from "../../store/slicers/breadcrumbs.slicer";
import { settingsCrumbs } from "../breadcrumbs";
import * as React from "react";

export const SettingsPage = () => {
  const appDispatch = useAppDispatch();
  const userSettings = useAppSelector((state) => state.userSettings);
  
  useEffect(() => {
    appDispatch(setBreadcrumbs(settingsCrumbs()))
  }, []);

  const [companySearch, setCompanySearch] = useState<string | undefined>();
  const [debouncedCompanySearch, setDebouncedCompanySearch] = useState<
    string | undefined
  >();
  const [isSearchingCompanies, setIsSearchingCompanies] = useState(false);
  const [companies, setCompanies] = useState<CompanyDto[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<
    CompanyDto | undefined
  >();

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedCompanySearch(companySearch);
    }, 500); // 500ms debounce

    return () => {
      clearTimeout(handler);
    };
  }, [companySearch]);

  useEffect(() => {
    getCompanies();
  }, [debouncedCompanySearch]);

  const getCompanies = async () => {
    if (!debouncedCompanySearch) return;

    setIsSearchingCompanies(true);
    var result = await httpClient.get<CompanyDto[]>(
      `/kbo/${debouncedCompanySearch}`
    );
    setCompanies(result);
    setIsSearchingCompanies(false);
  };

  const addSelectedCompany = async () => {
    if (!selectedCompany?.vat) return;
    await httpClient.post(`/kbo/register/${selectedCompany?.vat}`, {});
  };

  const selectCompany = async (id: number) => {
    appDispatch(setSelectedUserCompany(id));
  };

  return (
    <Grid2 container direction="column">
      <Grid2>
        <Grid2 container justifyContent="space-between">
          <Grid2>
            <Typography variant="h4"> Uw bedrijven</Typography>
          </Grid2>
        </Grid2>
      </Grid2>
      <Grid2>
        <List>
          <Divider />
          {userSettings.companies.map((x: CompanyDto) => (
            <React.Fragment key={x.id}>
              <ListItemButton
                onClick={() => selectCompany(x.id)}
                selected={userSettings.selectedCompanyId == x.id}
              >
                <Box mr={1}>
                  <Checkbox checked={userSettings.selectedCompanyId == x.id}/>
                </Box>
                <ListItemText
                  primary={
                    <Typography>
                      {" "}
                      <b>{x.name}</b> BE{x.vat}
                    </Typography>
                  }
                  secondary={`${x.street} ${x.houseNumber}, ${x.zip} ${x.city}`}
                />
                {/* <Button startIcon={<LoginIcon/>}>Selecteer</Button> */}
                <Button startIcon={<DeleteIcon />} color="error">
                  Verlaten
                </Button>
              </ListItemButton>
              <Divider />
            </React.Fragment>
          ))}
          <br />
          <ListItem disablePadding>
            <Grid2
              container
              direction="row"
              spacing={1}
              alignItems="center"
              width="inherit"
            >
              <Grid2 size="grow">
                <Autocomplete
                  fullWidth
                  loading={isSearchingCompanies}
                  autoComplete={false}
                  getOptionKey={(x: any) => x.key}
                  getOptionLabel={(x: any) =>
                    `BE${x.vat} - ${x.name}, ${x.street} ${x.houseNumber}, ${x.zip} ${x.city}`
                  }
                  onInputChange={(_, newInputValue) => {
                    setCompanySearch(newInputValue);
                  }}
                  onChange={(_, val: any) => setSelectedCompany(val)}
                  options={companies}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Zoek een bedrijf op naam of BTW nr."
                      //   slotProps={{
                      //     input: {
                      //       startAdornment: (
                      //         <InputAdornment position="start">
                      //             {isSearchingCompanies && <CircularProgress size={20} /> }
                      //         </InputAdornment>
                      //       ),
                      //     },
                      //   }}
                    />
                  )}
                />
              </Grid2>
              <Grid2>
                <Button
                  disabled={!selectedCompany}
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={() => addSelectedCompany()}
                >
                  Toevoegen
                </Button>
              </Grid2>

              <Grid2 size={12} container justifyContent="center">
                <Tooltip title="Hier komt popup om zelf gegevens in te vullen">
                  <Button variant="text" startIcon={<HelpIcon />}>
                    Ik sta er niet tussen
                  </Button>
                </Tooltip>
              </Grid2>
            </Grid2>
          </ListItem>
          <ListItem>
            <Grid2 container direction="column" spacing={1}>
              <Grid2>
                  <TextField label="Tel" variant="standard"/>
              </Grid2>
              <Grid2>
                  <TextField label="Email" variant="standard"/>
              </Grid2>
            </Grid2>
          </ListItem>
        </List>
      </Grid2>
    </Grid2>
  );
};
