import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  Grid,
  Grid2,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Skeleton,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";

import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/adminator-store";
import {
  clearQuoteSavedId,
  createQuoteAction,
  fetchQuoteEndCustomers,
  QuoteDto,
  QuoteHistoryDto,
} from "../../store/slicers/quotes.slicer";
import { setBreadcrumbs } from "../../store/slicers/breadcrumbs.slicer";
import { invoiceDetailsCrumbs } from "../breadcrumbs";

import SaveIcon from "@mui/icons-material/Save";
import ShareIcon from "@mui/icons-material/Share";
import SendIcon from "@mui/icons-material/Send";
import BackIcon from "@mui/icons-material/ArrowBack";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";

import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";

import { PdfViewer } from "../../components/shared/PdfViewer";
import { QuoteDetailsComponent } from "./components/details/quote-details.component";
import * as moment from "moment";

export const QuotePage = () => {
  const appDispatch = useAppDispatch();
  const navigate = useNavigate();

  const { id } = useParams();

  const selectedCompanyId = useAppSelector(
    (state) => state.userSettings.selectedCompanyId
  );

  const [showPdf, setShowPdf] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);

  const quote: QuoteDto = useAppSelector((state) =>
    state.quotes.quotes.find((x) => x.id == parseInt(id))
  );
  const [editingQuote, setEditingQuote] = useState(quote);

  const isSavingQuote: boolean = useAppSelector(
    (state) => state.quotes.isSaving
  );

  const quoteSavedId: number | undefined = useAppSelector(
    (state) => state.quotes.quoteSavedId
  );

  useEffect(() => {
    if (!quote) return;
    appDispatch(setBreadcrumbs(invoiceDetailsCrumbs(quote.id, quote.title)));
  }, [quote]);

  useEffect(() => {
    if (quoteSavedId) {
      navigate(`/quotes/edit-quote/${quoteSavedId}`);
      appDispatch(clearQuoteSavedId());
    }
  }, [quoteSavedId]);

  useEffect(() => {
    if (!selectedCompanyId) return;
    appDispatch(fetchQuoteEndCustomers(selectedCompanyId));
  }, [selectedCompanyId]);

  const createQuoteClicked = async () => {
    appDispatch(
      createQuoteAction({
        companyId: selectedCompanyId,
        quote: editingQuote,
      })
    );
  };

  const onQuoteDetailsChanged = (quote: QuoteDto) => {
    setEditingQuote(quote);
  };

  const getHistory = (history: QuoteHistoryDto) => {
    console.log(history);
    if (history.operationType == 0) {
      return (
        <Typography>
          <b>{history.propertyName}</b> aangemaakt.
        </Typography>
      );
    }

    if (history.operationType == 1) {
      return (
        <Typography>
          <b>{history.propertyName}</b> aangepast{" "}
          {history.from && (
            <>
              van <b>{history.from}</b>
            </>
          )}{" "}
          naar <b>{history.to}</b>.
        </Typography>
      );
    }

    if (history.operationType == 2) {
      return (
        <Typography>
          <b>{history.propertyName}</b> verwijderd.
        </Typography>
      );
    }
  };

  if (!quote) return <Skeleton height={500} />;

  return (
    <>
      <Grid2 container spacing={2}>
        <Grid2
          size={12}
          style={{
            position: "sticky",
            top: 10,
            // bottom: 0,
            // right: 0,
            // top: 0,
            zIndex: 9,
          }}
        >
          <Paper className="blurred">
            <Box p={2}>
              <Grid2
                container
                justifyContent="space-between"
                alignItems="center"
                size="grow"
                spacing={1}
              >
                <Grid2 container size="grow">
                  <Paper>
                    <Tabs
                      value={selectedTab}
                      onChange={(e, index: number) => setSelectedTab(index)}
                    >
                      <Tab label={"Details"} />
                      <Tab label={"Historiek"} />
                    </Tabs>
                  </Paper>
                </Grid2>
                <Grid2>
                  <Button
                    variant="outlined"
                    onClick={() => createQuoteClicked()}
                    color="secondary"
                    startIcon={
                      isSavingQuote ? (
                        <CircularProgress size={20} />
                      ) : (
                        <ShareIcon />
                      )
                    }
                    disabled={true || isSavingQuote}
                  >
                    Deel met collega
                  </Button>
                </Grid2>
                <Grid2>
                  <Button
                    variant="contained"
                    onClick={() => createQuoteClicked()}
                    color="primary"
                    startIcon={
                      isSavingQuote ? (
                        <CircularProgress size={20} />
                      ) : (
                        <SendIcon />
                      )
                    }
                    disabled={true || isSavingQuote}
                  >
                    Verstuur naar klant
                  </Button>
                </Grid2>
                <Grid2 size="grow"></Grid2>
                <Grid2>
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<InsertDriveFileIcon />}
                    onClick={() => setShowPdf(true)}
                  >
                    PDF tonen
                  </Button>
                </Grid2>
                <Grid2>
                  <Button
                    variant="contained"
                    onClick={() => createQuoteClicked()}
                    startIcon={
                      isSavingQuote ? (
                        <CircularProgress size={20} />
                      ) : (
                        <SaveIcon />
                      )
                    }
                    disabled={isSavingQuote}
                  >
                    Opslaan
                  </Button>
                </Grid2>
              </Grid2>
            </Box>
          </Paper>
        </Grid2>
        <Grid2 size={12}>
          {quote && selectedTab == 0 && (
            <QuoteDetailsComponent
              quote={quote}
              onChange={(quote) => onQuoteDetailsChanged(quote)}
            />
          )}
          {quote && selectedTab == 1 && (
            <Paper>
              {quote.history.length == 0 && (
                <Grid2
                  container
                  justifyContent="center"
                  alignItems="center"
                  p={5}
                >
                  <Grid2>
                    <Typography variant="subtitle2">
                      Nog geen wijzigingen.
                    </Typography>
                  </Grid2>
                </Grid2>
              )}
              <Timeline>
                {quote.history.map((x, i) => (
                  <TimelineItem key={i}>
                    <TimelineOppositeContent color="textSecondary">
                      <Grid2
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        height="100%"
                      >
                        <Typography>
                          {moment(x.createdDate)
                            .local()
                            .format("HH:mm:ss DD/MM/yyyy")}
                        </Typography>
                      </Grid2>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineConnector />
                      <TimelineDot color="secondary" />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      <ListItem>
                        <Box mr={1}>
                          <Avatar
                            src={x?.profilePicUrl}
                            alt="fallback"
                            imgProps={{ referrerPolicy: "no-referrer" }}
                          ></Avatar>
                        </Box>
                        <ListItemText
                          primary={getHistory(x)}
                          secondary={`${x.name} op ${moment(x.createdDate)
                            .local()
                            .format("HH:mm:ss DD/MM/yyyy")}`}
                        ></ListItemText>
                      </ListItem>
                    </TimelineContent>
                  </TimelineItem>
                ))}
              </Timeline>
            </Paper>
          )}
        </Grid2>
      </Grid2>

      <Dialog open={showPdf} onClose={() => setShowPdf(false)}>
        <Box height="90vh" width="calc(90vh * 0.707)">
          <PdfViewer
            url={`${
              process.env.REACT_APP_BACKEND_API_BASE_URL || ""
            }/reporting/quote/${id}`}
          />
        </Box>
        <DialogActions>
          <Button onClick={() => setShowPdf(false)} variant="contained">
            Sluit
          </Button>
        </DialogActions>
      </Dialog>
      {/* <Document
        file={`${
          process.env.REACT_APP_BACKEND_API_BASE_URL || ""
        }/reporting/quote/${id}#view=FitH`}
      >
        <Page pageNumber={0}/>
      </Document> */}
    </>
  );
};
