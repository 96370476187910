import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  createQuote,
  getQuoteEndCustomers,
  getQuotes,
} from "../../infrastructure/hub/dashboard-hub";

export const getTotalQuoteValueInclVat = (quote: QuoteDto): number => {
  return (
    quote.lines?.reduce(
      (prev, curr) => prev + getQuoteLineValueInclVat(curr),
      0
    ) ?? 0
  );
};

export const getTotalQuoteValueExclVat = (quote: QuoteDto): number => {
  return (
    quote.lines?.reduce(
      (prev, curr) => prev + getQuoteLineValueExclVat(curr),
      0
    ) ?? 0
  );
};

export const getQuoteLineValueInclVat = (quoteItem: QuoteItemDto): number => {
  return getQuoteLineValueExclVat(quoteItem) * (1 + (quoteItem.vat ?? 0) / 100);
};

export const getQuoteLineValueExclVat = (quoteItem: QuoteItemDto): number => {
  return quoteItem.amount * quoteItem.price;
};

export enum QuoteStatus {
  Draft = 0,
  Completed = 20,
  SendToEndCustomer = 30,
  ApprovedByEndCustomer = 50,
  DeniedByEndCustomer = 90,
}

export interface QuoteDto {
  id?: number;
  quoteNr: string;
  title: string;
  lines: QuoteItemDto[];
  endCustomer?: QuoteEndCustomerDto;
  expireDate: Date;
  createdDate?: Date;
  updatedDate?: Date;
  status?: QuoteStatus;
  messages: QuoteMessageDto[];
  history: QuoteHistoryDto[];
}

export interface QuoteHistoryDto {
  name: string;
  profilePicUrl: string;
  propertyName: string;
  from?: string;
  to?: string;
  operationType: number;
  createdDate: Date;
}
export interface QuoteMessageDto {
  name: string;
  profilePicUrl: string;
  message: string;
  createdDate: Date;
}


export interface QuoteEndCustomerDto {
  id?: number;
  name?: string;
  vat?: string;
  address?: string;
  zip?: string;
  city?: string;
}

export interface QuoteItemDto {
  id?: number;
  stockItemId?: number;
  description?: string;
  price?: number;
  amount: number;
  vat: number;
  notes?: string;
}

export interface QuotesState {
  isLoading: boolean;
  isSaving: boolean;
  quotes: QuoteDto[];
  quoteSavedId?: number;
  endCustomers: QuoteEndCustomerDto[];
}

export const fetchQuotes = createAsyncThunk(
  "quotes/get",
  async (companyId: number) => {
    const response = await getQuotes(companyId);
    return response;
  }
);

export const fetchQuoteEndCustomers = createAsyncThunk(
  "quotes/get-end-customers",
  async (companyId: number) => {
    const response = await getQuoteEndCustomers(companyId);
    return response;
  }
);

export const createQuoteAction = createAsyncThunk(
  "quotes/create",
  async ({ companyId, quote }: { companyId: number; quote: QuoteDto }) => {
    return await createQuote(companyId, quote);
  }
);

const initialState: QuotesState = {
  isLoading: true,
  isSaving: false,
  quotes: [],
  endCustomers: [],
};

export const quoteSlicer = createSlice({
  name: "quotes",
  initialState,
  reducers: {
    addQuoteAction: (state, action: PayloadAction<QuoteDto>) => {
      debugger;
      const existingIndex = state.quotes.findIndex(
        (x) => x.id == action.payload.id
      );
      let clones = [...state.quotes];
      if (existingIndex >= 0) {
        clones[existingIndex] = action.payload;
      } else {
        clones.push(action.payload);
      }
      state.quotes = clones;
    },
    updateQuoteAction: (state, action: PayloadAction<QuoteDto>) => {
      const existingIndex = state.quotes.findIndex(
        (x) => x.id == action.payload.id
      );

      let clones = [...state.quotes];
      if (existingIndex >= 0) {
        clones[existingIndex] = action.payload;
        state.quotes = clones;
      }
    },
    addQuoteMessageAction: (state,  action: PayloadAction<{quoteId: number, message: QuoteMessageDto}>) => {
      const existingIndex = state.quotes.findIndex(
        (x) => x.id == action.payload.quoteId
      );

      let clones = [...state.quotes];
      if (existingIndex >= 0) {
        clones[existingIndex].messages.push(action.payload.message);
        state.quotes = clones;
      }
    },
    clearQuoteSavedId: (state) => {
      state.quoteSavedId = undefined;
    },
  },
  extraReducers: (builder) =>
    builder
      //Fetch quotes
      .addCase(fetchQuotes.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchQuotes.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(
        fetchQuotes.fulfilled,
        (state, action: PayloadAction<QuoteDto[]>) => {
          state.quotes = [...(action.payload ?? [])];
          state.isLoading = false;
        }
      )
      //Fetch endcustomers
      .addCase(
        fetchQuoteEndCustomers.fulfilled,
        (state, action: PayloadAction<QuoteEndCustomerDto[]>) => {
          state.endCustomers = [...(action.payload ?? [])];
        }
      )
      // Save Quote
      .addCase(createQuoteAction?.pending, (state) => {
        state.isSaving = true;
        console.log(state.isSaving);
      })
      .addCase(createQuoteAction.rejected, (state) => {
        state.isSaving = false;
        console.log(state.isSaving);
      })
      .addCase(
        createQuoteAction.fulfilled,
        (state, action: PayloadAction<number>) => {
          state.isSaving = false;
          state.quoteSavedId = action.payload;
          console.log(state.isSaving);
        }
      ),
});

export const { addQuoteAction, updateQuoteAction, clearQuoteSavedId, addQuoteMessageAction } = quoteSlicer.actions;
