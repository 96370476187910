import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid2,
  List,
  ListItem,
  ListItemText,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  tableRowClasses,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  getQuoteLineValueExclVat,
  getQuoteLineValueInclVat,
  QuoteDto,
  QuoteEndCustomerDto,
  QuoteItemDto,
} from "../../../../store/slicers/quotes.slicer";
import * as moment from "moment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import * as dayjs from "dayjs";
import { CustomerInformationComponent } from "../../../../components/shared/customer-information";
import { useAppSelector } from "../../../../store/adminator-store";
import { QuoteItemComponent } from "../../../../components/quotes/quote-item.component";

import ChatIcon from "@mui/icons-material/Chat";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ListAltIcon from "@mui/icons-material/ListAlt";
import AddIcon from "@mui/icons-material/Add";
import { NumericFormatCustom } from "../../../../components/shared/NumericFormatCustom";
import { CommentSection } from "../comments/comment-section.component";
import { addQuoteMessage } from "../../../../infrastructure/hub/dashboard-hub";
import PersonIcon from "@mui/icons-material/Person";

moment().locale('de');

export interface Props {
  quote: QuoteDto;
  onChange: (quote: QuoteDto) => void;
}

export const QuoteDetailsComponent = (props: Props) => {
  const endCustomers: QuoteEndCustomerDto[] = useAppSelector(
    (state) => state.quotes.endCustomers
  );

  const selectedCompanyId = useAppSelector(
    (state) => state.userSettings.selectedCompanyId
  );

  const articles = [];

  const [quote, setQuote] = useState<QuoteDto>(props.quote);
  const [totalexclBTW, setTotalexclBTW] = useState(0);
  const [totalInclBTW, settTotalInclBTW] = useState(0);

  useEffect(() => {
    setQuote(props.quote);
  }, [props.quote]);

  useEffect(() => {
    setTotalexclBTW(
      quote.lines.reduce(
        (prev, curr) => prev + getQuoteLineValueExclVat(curr),
        0
      )
    );
    settTotalInclBTW(
      quote.lines.reduce(
        (prev, curr) => prev + getQuoteLineValueInclVat(curr),
        0
      )
    );
  }, [quote]);

  useEffect(() => {
    props.onChange(quote);
    console.log("quote change triggered");
  }, [quote]);

  const setQuoteName = (name: string) => {
    let clone = { ...quote };
    clone.title = name;
    setQuote(clone);
  };

  const setQuoteNr = (nr: string) => {
    let clone = { ...quote };
    clone.quoteNr = nr;
    setQuote(clone);
  };

  const setExpiredInDays = (date: Date) => {
    let clone = { ...quote };
    clone.expireDate = date;
    setQuote(clone);
  };

  const setEndCustomer = (endCustomer: QuoteEndCustomerDto) => {
    let clone = { ...quote };
    clone.endCustomer = endCustomer;
    setQuote(clone);
  };

  const addQuoteItem = (quoteItem: any) => {
    let clone = { ...quote } as any;
    clone.lines = [...clone.lines, quoteItem];
    setQuote(clone);
  };

  const updateQuoteItem = (quoteItem: QuoteItemDto, index: number) => {
    let clone = { ...quote } as QuoteDto;
    let linesClone = [...clone.lines];
    linesClone[index] = quoteItem;
    clone.lines = linesClone;
    setQuote(clone);
  };

  const removeQuoteItem = (index: number) => {
    let clone = { ...quote } as any;
    clone.lines = clone.lines.filter((_, i) => i != index);
    setQuote(clone);
  };

  const sendMessage = async (message: string) => {
    await addQuoteMessage(selectedCompanyId, quote.id!, message);
  };

  return (
    <Grid2 container pb={10} direction="column" spacing={2}>
      <Grid2 container size="grow">
        <Grid2
          size={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 6 }}
          container
          alignItems="stretch"
          justifyContent="stretch"
        >
          <Paper elevation={3} style={{ width: "100%" }}>
            <List>
              <ListItem>
                <Typography color="primary" variant="h5" fontWeight="bold">
                  Offerte details
                </Typography>
              </ListItem>
              <ListItem>
                <Grid2 width="inherit" container direction="row" spacing={1}>
                  <Grid2 size={8}>
                    <TextField
                      fullWidth
                      label="Offerte Naam"
                      variant="outlined"
                      size="small"
                      required
                      value={quote.title}
                      onChange={(e) => setQuoteName(e.target.value)}
                    />
                  </Grid2>
                  <Grid2 size={4}>
                    <TextField
                      fullWidth
                      label="Offerte Nr"
                      variant="outlined"
                      required
                      size="small"
                      slotProps={{
                        input: {
                          style: {
                            textAlign: "right",
                          },
                        },
                      }}
                      value={quote.quoteNr}
                      onChange={(e) => setQuoteNr(e.target.value)}
                    />
                  </Grid2>
                </Grid2>
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Aangemaakt"
                  secondary={moment(quote.createdDate).local().format("LLL")}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Bijgewerkt"
                  secondary={moment(quote.updatedDate).local().format("LLL")}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Vervaldatum"
                  secondary={
                    <Grid2
                      container
                      direction="row"
                      justifyContent="stretch"
                      alignItems="center"
                    >
                      <Grid2 size={6}>
                        <DatePicker
                          slotProps={{
                            textField: {
                              size: "small",
                            },
                          }}
                          minDate={dayjs(new Date())}
                          value={dayjs(quote.expireDate)}
                          onChange={(e) => {
                            const createDate = moment(
                              quote.createdDate
                            ).local();
                            setExpiredInDays(e.toDate());
                          }}
                        />
                      </Grid2>
                      <Grid2 size={6} container justifyContent="center">
                        <Typography variant="caption">
                          {moment(quote.expireDate).fromNow(true)} van nu.
                        </Typography>
                      </Grid2>
                    </Grid2>
                  }
                />
              </ListItem>
            </List>
          </Paper>
        </Grid2>
        <Grid2 size={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 6 }} height="inherit">
          <Paper elevation={3}>
            <List>
              <ListItem>
                <Grid2
                  container
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                >
                  <PersonIcon color="primary" />
                  <Typography color="primary" variant="h5" fontWeight="bold">
                    Klantgegevens
                  </Typography>
                </Grid2>
              </ListItem>
              <ListItem>
                <CustomerInformationComponent
                  quoteEndCustomers={endCustomers}
                  data={quote.endCustomer}
                  onChange={(val: QuoteEndCustomerDto) => setEndCustomer(val)}
                  style={{ width: "100%" }}
                />
              </ListItem>
            </List>
          </Paper>
        </Grid2>
      </Grid2>
      {/* <Grid2 container size="grow">
        <Accordion
          disableGutters
          defaultExpanded
          elevation={3}
          style={{ width: "100%" }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Grid2
              container
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >
              <ListAltIcon color="primary" />
              <Typography color="primary" variant="h5" fontWeight="bold">
                Items
              </Typography>
            </Grid2>
          </AccordionSummary>
          <AccordionDetails></AccordionDetails>
        </Accordion>
      </Grid2>{" "} */}
      <Grid2
        style={{
          width: "100%"
        }}
      >
        <Paper elevation={3}>
          <Grid2 container p={2} direction="column" justifyContent="flex-start">
            <Grid2 container alignItems="center" spacing={1}>
              <ListAltIcon color="primary" />
              <Typography color="primary" variant="h5" fontWeight="bold">
                Items
              </Typography>
            </Grid2>
            <Grid2 style={{ width: "100%" }}>
              <Grid2
                container
                direction="column"
                justifyContent="stretch"
                spacing={1}
                style={{
                  maxWidth: "inherit", // Limit table width to the parent container width
                  overflowX: "auto", // Allow horizontal scroll within the table container only
                  overflowY: "hidden", // Optionally, you can hide vertical overflow
                  whiteSpace: "nowrap", // Prevent table rows from wrapping,
                }}
              >
                <TableContainer>
                  <Box>
                    <Table
                      stickyHeader
                      sx={{
                        [`& .${tableCellClasses.root}`]: {
                          borderBottom: "none",
                          verticalAlign: "bottom",
                          paddingLeft: 0.5,
                          paddingRight: 0.1,
                          paddingUp: 0.1,
                          paddingDown: 0.1,
                        },
                        [`& .${tableRowClasses.root}`]: {},
                      }}
                      // style={{ tableLayout: "auto", width: "100%" }} // Ensure the table takes the full width
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">Product/Service</TableCell>
                          <TableCell align="center">Stuks</TableCell>
                          <TableCell align="right">Eenheidsprijs</TableCell>
                          <TableCell align="center">BTW</TableCell>
                          <TableCell align="right">Excl. BTW</TableCell>
                          <TableCell align="right">Incl. BTW</TableCell>
                          <TableCell align="center"></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {(quote.lines ?? []).map((quoteItem, index) => {
                          const article = articles.find(
                            (x) => x.id == quoteItem.stockItemId
                          );
                          return (
                            <QuoteItemComponent
                              key={index}
                              quoteItem={quoteItem}
                              article={article}
                              onChange={(val) => updateQuoteItem(val, index)}
                              onRemove={() => removeQuoteItem(index)}
                            />
                          );
                        })}
                        <TableRow>
                          <TableCell colSpan={7}>
                            <Grid2 container justifyContent="center">
                              <Button
                                startIcon={<AddIcon />}
                                onClick={() => {
                                  addQuoteItem({});
                                }}
                                variant="contained"
                              >
                                Voeg toe
                              </Button>
                            </Grid2>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="center" colSpan={4}></TableCell>
                          <TableCell align="left">
                            <Box pr={1}>
                              <Typography variant="subtitle1">
                                Excl. BTW
                              </Typography>
                            </Box>
                          </TableCell>
                          <TableCell align="right">
                            <TextField
                              value={totalexclBTW}
                              variant="outlined"
                              size="small"
                              fullWidth
                              sx={{
                                [`& input`]: {
                                  textAlign: "right",
                                },
                              }}
                              slotProps={{
                                input: {
                                  inputComponent: NumericFormatCustom as any,
                                  slotProps: {
                                    input: {
                                      prefix: "€ ",
                                    },
                                  },
                                  readOnly: true,
                                  style: {
                                    resize: "both",
                                    minWidth: 150,
                                  },
                                },
                              }}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="center" colSpan={4}></TableCell>
                          <TableCell align="left">
                            <Box pr={1}>
                              <Typography variant="subtitle1">
                                Totaal
                              </Typography>
                            </Box>
                          </TableCell>
                          <TableCell>
                            <TextField
                              value={totalInclBTW}
                              variant="outlined"
                              size="small"
                              fullWidth
                              sx={{
                                [`& input`]: {
                                  textAlign: "right",
                                },
                              }}
                              slotProps={{
                                input: {
                                  inputComponent: NumericFormatCustom as any,
                                  slotProps: {
                                    input: {
                                      prefix: "€ ",
                                    },
                                  },
                                  readOnly: true,
                                  style: {
                                    resize: "both",
                                    minWidth: 150,
                                    fontWeight: "bold",
                                  },
                                },
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Box>
                </TableContainer>
              </Grid2>
            </Grid2>
          </Grid2>
        </Paper>
      </Grid2>
      <Grid2>
        <Paper elevation={3}>
          <Grid2 container p={2} direction="column" justifyContent="flex-start">
            <Grid2 container alignItems="center" spacing={1}>
              <ChatIcon color="primary" />
              <Typography color="primary" variant="h5" fontWeight="bold">
                Feedback
              </Typography>
            </Grid2>
            <Grid2>
              <CommentSection
                comments={quote.messages.map((x) => ({
                  time: x.createdDate,
                  sender: x.name,
                  isOwnMessage: true,
                  message: x.message,
                  profilePicUrl: x.profilePicUrl,
                }))}
                onSend={sendMessage}
              />
            </Grid2>
          </Grid2>
        </Paper>
      </Grid2>
    </Grid2>
  );
};
