import {
  Box,
  FormControl,
  Grid2,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import * as React from "react";
import { useEffect } from "react";
import { QuoteEndCustomerDto } from "../../store/slicers/quotes.slicer";

interface Props {
  data?: QuoteEndCustomerDto;
  quoteEndCustomers: QuoteEndCustomerDto[];
  onChange: (val: QuoteEndCustomerDto) => void;
  style?: React.CSSProperties;
}

export const CustomerInformationComponent = (props: Props) => {
  const [endCustomerData, setEndCustomerdata] = React.useState(props.data);

  useEffect(() => {
    props.onChange(endCustomerData ?? {});
  }, [endCustomerData]);

  useEffect(() => {
    setEndCustomerdata(props.data);
  }, [props.data]);

  const onEndCustomerChanged = (id: number) => {
    setEndCustomerdata(props.quoteEndCustomers.find((x) => x.id == id));
  };

  return (
    <Box p={2} style={{ ...props.style }}>
      <Grid2
        container
        height="stretch"
        width="stretch"
        direction="column"
        spacing={1}
      >
        <Grid2>
          <Grid2
            container
            justifyContent="flex-end"
            direction="row"
            spacing={1}
          >
            <Grid2 size={12}>
              <FormControl size="small" fullWidth>
                <InputLabel>Bestaande klant zoeken...</InputLabel>
                <Select
                  value={endCustomerData?.id ?? ""}
                  onChange={(e) =>
                    onEndCustomerChanged(parseInt(e.target.value as any))
                  }
                  label="Bestaande klant zoeken..."
                  variant="outlined"
                >
                  {props.quoteEndCustomers.map((x: QuoteEndCustomerDto) => (
                    <MenuItem key={x.id} value={x.id}>
                      {x.name}, {x.address}, {x.zip} {x.city}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid2>
          </Grid2>
        </Grid2>
        <Grid2>
          <TextField
            fullWidth
            size="small"
            id="end-customer-name"
            label="Klant naam"
            variant="outlined"
            value={endCustomerData?.name ?? ""}
            onChange={(e) => {
              let clone = { ...endCustomerData };
              clone.name = e.target.value;
              setEndCustomerdata(clone);
            }}
          />
        </Grid2>
        <Grid2>
          <TextField
            fullWidth
            size="small"
            id="end-customer-vat"
            label="BTW Nr."
            variant="outlined"
            value={endCustomerData?.vat ?? ""}
            onChange={(e) => {
              let clone = { ...endCustomerData };
              clone.vat = e.target.value;
              setEndCustomerdata(clone);
            }}
          />
        </Grid2>
        <Grid2>
          <TextField
            fullWidth
            size="small"
            id="end-customer-street"
            label="Straat + nummer"
            variant="outlined"
            value={endCustomerData?.address ?? ""}
            onChange={(e) => {
              let clone = { ...endCustomerData };
              clone.address = e.target.value;
              setEndCustomerdata(clone);
            }}
          />
        </Grid2>
        <Grid2>
          <Grid2 container spacing={1} justifyContent="stretch" direction="row">
            <Grid2 size={6}>
              <TextField
                fullWidth
                size="small"
                id="end-customer-zip"
                label="Postcode"
                variant="outlined"
                value={endCustomerData?.zip ?? ""}
                onChange={(e) => {
                  let clone = { ...endCustomerData };
                  clone.zip = e.target.value;
                  setEndCustomerdata(clone);
                }}
              />
            </Grid2>
            <Grid2 size={6}>
              <TextField
                fullWidth
                size="small"
                id="end-customer-city"
                label="Plaats"
                variant="outlined"
                value={endCustomerData?.city ?? ""}
                onChange={(e) => {
                  let clone = { ...endCustomerData };
                  clone.city = e.target.value;
                  setEndCustomerdata(clone);
                }}
              />
            </Grid2>
          </Grid2>
        </Grid2>
        {/* <Grid2>
          <TextField
            fullWidth
            id="end-customer-country"
            label="Land"
            variant="outlined"
            value={endCustomerData?.city}
            onChange={(e) => {
              let clone = { ...endCustomerData };
              clone.city = e.target.value;
              setEndCustomerdata(clone);
            }}
          />
        </Grid2> */}
      </Grid2>
    </Box>
  );
};
