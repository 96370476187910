import { Breadcrumb } from "../store/slicers/breadcrumbs.slicer";

export const invoiceCrumbs = (): Breadcrumb[] => [
  { link: "/dashboard", title: "Dashboard" },
  { link: "/quotes", title: "Offertes" },
];

export const invoiceDetailsCrumbs = (
  invoiceId?: number,
  invoiceTitle?: string
): Breadcrumb[] => [
  ...invoiceCrumbs(),
  {
    link: `quotes/edit-quote/${invoiceId}`,
    title: invoiceTitle ?? "Nieuwe offerte",
  },
];

export const quoteIntegrationCrumbs = (): Breadcrumb[] => [
  { link: "/dashboard", title: "Dashboard" },
  { link: "/quote-integration", title: "Offerte integraties" },
];

export const settingsCrumbs = (): Breadcrumb[] => [
  { link: "/dashboard", title: "Dashboard" },
  { link: "/settings", title: "Beheer" },
];

export const articlesCrumbs = (): Breadcrumb[] => [
  { link: "/dashboard", title: "Dashboard" },
  { link: "/articles", title: "Artikels" },
];
