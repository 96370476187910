const baseUrl = process.env.REACT_APP_BACKEND_API_BASE_URL || '';

class HttpClient {

    headers: any = {
        "content-type": "application/json"
    };

    get = async <T>(url: string): Promise<T> => {
        const res = await fetch(baseUrl + url, {
            headers: this.headers
        });
        return await res.json() as Promise<T>;
    };

    post = async <TResult>(url: string, body?: any): Promise<TResult> => {
        const res = await fetch(baseUrl + url, { method: "POST", body: JSON.stringify(body), headers: this.headers });

        if (res.status === 204 || res.headers.get('content-length') === '0') {
            return null as TResult; // If there is no content, return null or handle accordingly
        }

        try {
            return await res.json() as Promise<TResult>;
        }
        catch(error) {
            console.error(error);
            return null as TResult;
        }
    };

    postForm = async <TResult>(url: string, form?: any): Promise<TResult> => {
        const res = await fetch(baseUrl + url, { method: "POST", body: form});

        if (res.status === 204 || res.headers.get('content-length') === '0') {
            return null as TResult; // If there is no content, return null or handle accordingly
        }

        try {
            return await res.text() as TResult;
            // return await res.json() as Promise<TResult>;
        }
        catch(error) {
            console.error(error);
            return null as TResult;
        }
    };


    setAccessToken = (acces_token: string) => {
        this.headers["Authorization"] = `Bearer ${acces_token}`;
    }
}

export const getMediaUrl = (folder: string, fileName: string): string => {
    return baseUrl + "/media/" + folder + "/" + fileName;
};

export const httpClient = new HttpClient();

