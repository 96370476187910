import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid2,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Radio,
  RadioGroup,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../../store/adminator-store";
import { setBreadcrumbs } from "../../store/slicers/breadcrumbs.slicer";
import { quoteIntegrationCrumbs } from "../breadcrumbs";
import * as React from "react";

import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

const quoteIntegrations = [
  {
    id: 5,
    name: "Offerte integratie zonnepanelen",
    description:
      "Deze integratie maakt het mogelijk klanten een offerte te maken op basis van hun noden.",
    linkedQuotes: 15,
  },
  {
    id: 6,
    name: "Offerte integratie Warmtepompen",
    description:
      "Deze integratie maakt het mogelijk klanten een offerte te maken op basis van hun noden.",
    linkedQuotes: 15,
  },
];

const quoteIntegration = quoteIntegrations[0];

export const QuoteIntegrationBuilderPage = () => {
  const appDispatch = useAppDispatch();

  useEffect(() => {
    appDispatch(setBreadcrumbs(quoteIntegrationCrumbs()));
  }, []);

  const [activeStep, setActiveStep] = useState(0);
  const [optionValue, setOptionValue] = useState("");

  const [steps, setSteps] = useState([
    // { label: "Test label", content: [] },
    // { label: "Test label 2", content: [] },
    { label: "", options: [] },
  ]);

  const setStepLabel = (index: number, label: string) => {
    let clone = [...steps];
    clone[index].label = label;
    setSteps(clone);
  };

  const addStep = () => {
    let clone = [...steps];
    clone.push({ label: "", options: [] });
    setSteps(clone);
  };

  const removeStep = (index: number) => {
    let clone = [...steps];
    clone = clone.filter(
      (x, i) => i != index
    );
    setSteps(clone);
  };

  const addOption = (index: number) => {
    let clone = [...steps];
    clone[index].options = [
      ...clone[index].options,
      { description: optionValue },
    ];
    setSteps(clone);
    setOptionValue("");
  };

  const removeOption = (index, optionIndex) => {
    let clone = [...steps];
    clone[index].options = clone[index].options.filter(
      (x, i) => i != optionIndex
    );
    setSteps(clone);
  };

  return (
    <>
      <List>
        <Divider />
        {quoteIntegrations.map((x) => (
          <React.Fragment key={x.id}>
            <ListItem>
              <Grid2
                container
                alignItems="center"
                spacing={1}
                direction="row"
                size={12}
              >
                <Grid2 size="grow">
                  <ListItemText primary={x.name} secondary={x.description} />
                </Grid2>
                <Grid2>
                  <Typography>{x.linkedQuotes} nieuwe offertes</Typography>
                </Grid2>
                <Grid2>
                  <Button variant="contained" startIcon={<EditIcon />}>
                    Editeren
                  </Button>
                </Grid2>
              </Grid2>
            </ListItem>
            <Divider />
          </React.Fragment>
        ))}
      </List>

      <br />
      <Paper>
        <Grid2 container direction="column" spacing={2} p={1}>
          <Grid2>
            <TextField
              size="small"
              label="Naam"
              fullWidth
              defaultValue={quoteIntegration.name}
            />
          </Grid2>
          <Grid2>
            <TextField
              size="small"
              label="Omschrijving"
              multiline
              rows={2}
              fullWidth
              defaultValue={quoteIntegration.description}
            />
          </Grid2>
          <Grid2>
            <Typography textAlign="left" variant="h5">
                Stappen
            </Typography>
          </Grid2>
          <Grid2>
            <Stepper orientation="vertical" activeStep={activeStep}>
              {steps.map((step, index) => (
                <Step key={index}>
                  <StepLabel onClick={() => setActiveStep(index)} style={{cursor: 'pointer'}}>
                    <Grid2 container spacing={1}>
                      <Grid2 size="grow">
                        <TextField
                          label="Naam stap"
                          required
                          size="small"
                          value={step.label}
                          fullWidth
                          placeholder="Bv: Selecteer het aantal gezinsleden"
                          onChange={(e) => setStepLabel(index, e.target.value)}
                          onClick={() => setActiveStep(index)} 
                        />
                      </Grid2>
                      <Grid2>
                        <IconButton color="error" onClick={() => removeStep(index)}>
                          <DeleteIcon />
                        </IconButton>
                      </Grid2>
                    </Grid2>
                  </StepLabel>
                  <StepContent>
                    <Grid2
                      container
                      direction="column"
                      size={12}
                      alignItems="flex-start"
                      spacing={1}
                    >
                      <Grid2
                        container
                        direction="column"
                        size={12}
                        justifyContent="flex-start"
                      >
                        <List>
                          {step.options.map((option, optionIndex) => (
                            <ListItem disablePadding>
                              <ListItemText>
                                <FormControlLabel
                                  key={optionIndex}
                                  control={<Checkbox value={false} />}
                                  label={option.description}
                                />
                              </ListItemText>
                              <Button
                                variant="contained"
                                color="error"
                                onClick={() => removeOption(index, optionIndex)}
                              >
                                Verwijderen
                              </Button>
                            </ListItem>
                          ))}
                        </List>
                      </Grid2>
                      <Grid2>
                        <Typography textAlign="left" variant="subtitle2">
                          Optie Toevoegen
                        </Typography>
                      </Grid2>
                      <Grid2 size={12} container spacing={1}>
                        <Grid2 size="grow">
                          <TextField
                            fullWidth
                            size="small"
                            label="Omschrijving optie"
                            placeholder="Bv: minder dan 3"
                            value={optionValue}
                            onChange={(e) => setOptionValue(e.target.value)}
                          />
                        </Grid2>
                        <Grid2>
                          <Button
                            startIcon={<AddIcon />}
                            disabled={!optionValue}
                            onClick={() => addOption(index)}
                          >
                            Toevoegen
                          </Button>
                        </Grid2>
                      </Grid2>
                    </Grid2>
                    {/* <Box sx={{ mb: 2 }}>
                      <Button
                        variant="contained"
                        sx={{ mt: 1, mr: 1 }}
                        onClick={() =>
                          setActiveStep(
                            Math.min(activeStep + 1, steps.length - 1)
                          )
                        }
                      >
                        {index === steps.length - 1 ? "Klaar" : "Volgende stap"}
                      </Button>
                      <Button
                        disabled={index === 0}
                        sx={{ mt: 1, mr: 1 }}
                        onClick={() =>
                          setActiveStep(Math.max(activeStep - 1, 0))
                        }
                      >
                        Terug
                      </Button>
                    </Box> */}
                  </StepContent>
                </Step>
              ))}
            </Stepper>
          </Grid2>
          <Grid2>
            <Button variant="contained" onClick={() => addStep()}>
              Stap toevoegen
            </Button>
          </Grid2>
        </Grid2>
      </Paper>
    </>
  );
};
