import * as signalR from "@microsoft/signalr";
import { addQuoteAction, addQuoteMessageAction, QuoteDto, QuoteMessageDto } from "../../store/slicers/quotes.slicer";
import { adminatorStore } from "../../store/adminator-store";
import { addArticleAction, ArticleDto } from "../../store/slicers/articles.slicer";

export let hubConnection: signalR.HubConnection | null = null;

export const connectHub = async (token: string) => {
  hubConnection = new signalR.HubConnectionBuilder()
    .withUrl(`${process.env.REACT_APP_BACKEND_API_BASE_URL || ''}/hub/dashboard`, {
      accessTokenFactory: () => `${token}`,
    })
    .withAutomaticReconnect()
    .build();

  try {
    await hubConnection.start();
  } catch (err) {
    console.log(err);
  }
    
  hubConnection.on("quoteAdded", (quote: QuoteDto) => adminatorStore.dispatch(addQuoteAction(quote)));
  hubConnection.on("quoteMessageAdded", (message: QuoteMessageDto, quoteId: number) => adminatorStore.dispatch(addQuoteMessageAction({quoteId, message})));
  hubConnection.on("articleAdded", (article: ArticleDto) => adminatorStore.dispatch(addArticleAction(article)));
};

export const syncProfile = async (name?: string, profilePicUrl?: string) => {
  return await hubConnection?.invoke("SyncProfile", name, profilePicUrl);
};

export const selectCompany = async (id: number) => {
  console.time("getQuotes");
  const result = await hubConnection?.invoke("SelectCompany", id);;
  console.timeEnd("getQuotes");
  return result;
};

export const getQuotes = async (companyId: number): Promise<QuoteDto[]> => {
  console.time("getQuotes");
  const result = await hubConnection?.invoke("QuoteGet", companyId);
  console.timeEnd("getQuotes");
  return result;
};

export const getQuoteEndCustomers = async (companyId: number) => {
  return await hubConnection?.invoke("QuoteGetEndCustomers", companyId);
};

export const createQuote = async (companyId: number, command: QuoteDto): Promise<number> => {
  return await hubConnection?.invoke("QuoteCreate", companyId, command);
};

export const addQuoteMessage = async (companyId: number, quoteId: number, message: string): Promise<void> => {
  return await hubConnection?.invoke("QuoteAddMessage", companyId, quoteId, message);
};

export const getArticles = async (companyId: number) => {
  return await hubConnection?.invoke("ArticlesGet", companyId);
};

export const createOrUpdateArticle = async (companyId: number, article: ArticleDto) => {
  return await hubConnection?.invoke("ArticlesCreateOrUpdate", companyId, article);
};

