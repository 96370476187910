import { useAuth0 } from "@auth0/auth0-react";
import {
  Autocomplete,
  Button,
  CircularProgress,
  Divider,
  Grid2,
  TextField,
  Typography,
} from "@mui/material";
import * as React from "react";
import { httpClient } from "../../infrastructure/http-client/http-client";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../../store/adminator-store";
import { CompanyDto, fetchUserProfile } from "../../store/slicers/user-settings.slicer";

export const CreateCompanyPage = () => {
  const { user } = useAuth0();

  const [companySearch, setCompanySearch] = useState<string | undefined>();
  const [debouncedCompanySearch, setDebouncedCompanySearch] = useState<
    string | undefined
  >();
  const [isSearchingCompanies, setIsSearchingCompanies] = useState(false);
  const [companies, setCompanies] = useState<CompanyDto[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<
    CompanyDto | undefined
  >();

  const [loading, _] = React.useState(false);

  const navigate = useNavigate();
  const appDispatch = useAppDispatch();

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedCompanySearch(companySearch);
    }, 500); // 500ms debounce

    return () => {
      clearTimeout(handler);
    };
  }, [companySearch]);

  useEffect(() => {
    getCompanies();
  }, [debouncedCompanySearch]);

  const getCompanies = async () => {
    if (!debouncedCompanySearch) return;

    setIsSearchingCompanies(true);
    var result = await httpClient.get<CompanyDto[]>(
      `/kbo/${debouncedCompanySearch}`
    );
    setCompanies(result);
    setIsSearchingCompanies(false);
  };

  const addSelectedCompany = async () => {
    if (!selectedCompany?.vat) return;
    await httpClient.post(`/kbo/register/${selectedCompany?.vat}`, {});
    appDispatch(fetchUserProfile());
    navigate("/dashboard");
  };

  return (
    <Grid2
      container
      width="100vw"
      spacing={2}
      direction="column"
      height="100vh"
      justifyContent="center"
      alignContent="center"
      p={5}
    >
      <Grid2>
        <Typography variant="h4">
          Welkom bij adminator, {user?.given_name}
        </Typography>
      </Grid2>
      <Grid2>
        <Typography>
          Om uw registratie af te ronden hebben we enkel nog uw bedrijfsnaam
          nodig.
        </Typography>
      </Grid2>
      <Grid2>
        <Divider />
      </Grid2>
      <Grid2 size={{ xs: 12, md: 8 }}>
        <Autocomplete
          fullWidth
          loading={isSearchingCompanies}
          autoComplete={false}
          getOptionKey={(x: any) => x.key}
          getOptionLabel={(x: any) =>
            `BE${x.vat} - ${x.name}, ${x.street} ${x.houseNumber}, ${x.zip} ${x.city}`
          }
          onInputChange={(_, newInputValue) => {
            setCompanySearch(newInputValue);
          }}
          onChange={(_, val: any) => setSelectedCompany(val)}
          options={companies}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Zoek een bedrijf op naam of BTW nr."
              //   slotProps={{
              //     input: {
              //       startAdornment: (
              //         <InputAdornment position="start">
              //             {isSearchingCompanies && <CircularProgress size={20} /> }
              //         </InputAdornment>
              //       ),
              //     },
              //   }}
            />
          )}
        />
      </Grid2>
      <Grid2>
        {!loading && (
          <Button
            variant="outlined"
            onClick={() => addSelectedCompany()}
            disabled={!selectedCompany}
          >
            Verder
          </Button>
        )}
        {loading && (
          <Button
            disabled
            variant="outlined"
            startIcon={<CircularProgress size={20} />}
          >
            Verder
          </Button>
        )}
      </Grid2>
    </Grid2>
  );
};
