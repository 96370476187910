import {
  Alert,
  Avatar,
  Box,
  Button,
  CircularProgress,
  createStyles,
  Divider,
  Grid2,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Paper,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import "./comment-section.css";

import { useEffect, useRef, useState, useLayoutEffect } from "react";
import * as moment from "moment";

import SendIcon from "@mui/icons-material/Send";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import DeleteIcon from "@mui/icons-material/Delete";
import * as React from "react";

export interface CommentDto {
  time: Date;
  message: string;
  sender: string;
  isOwnMessage: boolean;
  profilePicUrl: string;
}

export interface IProps {
  comments: CommentDto[];
  onSend: (message: string) => Promise<void>;
}

export const CommentSection = (props: IProps) => {
  const theme = useTheme();

  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [attachedFiles, setAttachedFiles] = useState<File[]>([]);

  const messagesContainerRef = useRef<HTMLDivElement | null>(null);
  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const attachmentInputRef = useRef<HTMLInputElement | null>(null);

  const onSendClicked = async () => {
    setIsLoading(true);
    await props.onSend(message);
    setIsLoading(false);
    setMessage("");
    setAttachedFiles([]);
  };

  const addAttachment = () => {
    attachmentInputRef.current.click();
  };

  const removeAttachment = (index: number) => {
    let clone = [...attachedFiles];
    clone = clone.filter((x, i) => i != index);
    setAttachedFiles(clone);
  };

  // Scroll to the latest message whenever comments change after initial load
  useEffect(() => {
    if (
      !initialLoad &&
      messagesContainerRef.current &&
      messagesEndRef.current
    ) {
      messagesContainerRef.current.scrollTo({
        top: messagesEndRef.current.offsetTop,
        behavior: "smooth",
      });
    }
    setInitialLoad(false);
  }, [props.comments]);

  return (
    <Grid2
      container
      spacing={1}
      direction="column"
      alignItems="flex-end"
      justifyContent="flex-end"
    >
      <Grid2
        container
        direction="row"
        spacing={1}
        mt={1}
        maxHeight="500px"
        style={{
          overflowY: "auto",
          // backgroundImage:
          //   'url("https://www.shutterstock.com/image-vector/social-media-sketch-vector-seamless-600nw-1660950727.jpg")',
        }}
        size={12}
        p={2}
        ref={messagesContainerRef} // Add reference to the scrolling container
      >
        {props.comments.length == 0 && (
          <Grid2 size={12} my={2} container justifyContent="center">
            <Typography>Er zijn nog geen berichten uitgewisseld.</Typography>
          </Grid2>
        )}
        {props.comments.map((x, index) => (
          <Grid2
            key={index}
            size={12}
            className={`message-container ${
              x.isOwnMessage ? "own-message" : "other-message"
            }`}
          >
            <Paper
              className={`bubble ${
                x.isOwnMessage ? "own-bubble" : "other-bubble"
              }`}
              elevation={3}
              style={{ backgroundColor: theme.palette.primary.light }}
            >
              <Grid2 container direction="column" spacing={1} minWidth="250px">
                <Grid2 container alignItems="center" spacing={1}>
                  <Grid2>
                    <Avatar
                      sx={{ width: 30, height: 30 }}
                      src={x.profilePicUrl}
                      alt="fallback"
                      imgProps={{ referrerPolicy: "no-referrer" }}
                    ></Avatar>
                  </Grid2>
                  <Grid2>
                    <Typography
                      variant="subtitle2"
                      className="receiver"
                      color={theme.palette.primary.contrastText}
                    >
                      {x.sender}
                    </Typography>
                  </Grid2>
                </Grid2>
                <Grid2 container direction="column" justifyContent="flex-end">
                  <Typography
                    textAlign="left"
                    color={theme.palette.primary.contrastText}
                    style={{ wordBreak: "break-word", whiteSpace: "normal" }}
                  >
                    {x.message}
                  </Typography>
                  <Tooltip
                    title={moment(x.time)
                      .local()
                      .format("HH:mm:ss dddd DD/MM/yyyy")}
                  >
                    <Typography
                      variant="caption"
                      className="timestamp"
                      color={theme.palette.primary.contrastText}
                    >
                      {moment(x.time).local().fromNow()}
                    </Typography>
                  </Tooltip>
                </Grid2>
              </Grid2>
            </Paper>
          </Grid2>
        ))}
        {/* Dummy div to scroll to the end of messages */}
        <div ref={messagesEndRef} />
      </Grid2>
      <Grid2 size={12}>
        <TextField
          rows={4}
          multiline
          fullWidth
          variant="outlined"
          label="Opmerking"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
      </Grid2>
      <Grid2 size={12} container my={1}>
        <List style={{ width: "100%" }} disablePadding>
          {attachedFiles.map((f, i) => (
            <React.Fragment key={i}>
              <ListItem disablePadding>
                <ListItemIcon>
                  <InsertDriveFileIcon />
                </ListItemIcon>
                <ListItemText primary={f.name} secondary={f.type} />
                <Button
                  variant="contained"
                  color="error"
                  startIcon={<DeleteIcon />}
                  size="small"
                  onClick={() => removeAttachment(i)}
                >
                  Verwijderen
                </Button>
              </ListItem>
              <Divider />
            </React.Fragment>
          ))}
        </List>
      </Grid2>
      <Grid2 size={12} container direction="row" justifyContent="space-between">
        <Button
          disabled={isLoading}
          onClick={() => addAttachment()}
          startIcon={
            isLoading ? <CircularProgress size={20} /> : <AttachFileIcon />
          }
          variant="contained"
          color="secondary"
        >
          Bijlagen toevoegen
          <input
            id="myInput"
            type="file"
            ref={attachmentInputRef}
            style={{ display: "none" }}
            multiple
            onChange={(e) =>
              setAttachedFiles([
                ...attachedFiles,
                ...Array.from(e.target.files),
              ])
            }
          />
        </Button>
        <Button
          disabled={isLoading || !message}
          onClick={() => onSendClicked()}
          startIcon={isLoading ? <CircularProgress size={20} /> : <SendIcon />}
          variant="contained"
        >
          Verstuur
        </Button>
      </Grid2>
    </Grid2>
  );
};
