import { configureStore } from '@reduxjs/toolkit';
import { UserSettingsDto, userSettingsSlice } from './slicers/user-settings.slicer';
import { useDispatch, useSelector } from 'react-redux';
import { snackbackSlice, SnackbarStore } from './slicers/snackbar.slicer';
import { quoteSlicer } from './slicers/quotes.slicer';
import { BreadcrumbsSlicer, BreadcrumbsStore } from './slicers/breadcrumbs.slicer';
import { articleSlicer } from './slicers/articles.slicer';


interface AdminatorStore {
    userSettings: UserSettingsDto;
    snackbar: SnackbarStore;
    breakcrumbs: BreadcrumbsStore;
}

export const initialState = {
    userSettings: {
        isLoading: true
    }
} as AdminatorStore; 

export const adminatorStore = configureStore({
    reducer: {
        userSettings: userSettingsSlice.reducer,
        snackbar: snackbackSlice.reducer,
        quotes: quoteSlicer.reducer,
        breadcrumbs: BreadcrumbsSlicer.reducer,
        articleState: articleSlicer.reducer
    }
});

export type RootState = ReturnType<typeof adminatorStore.getState>;
export type AppDispatch = typeof adminatorStore.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: (selector: (state: RootState) => any) => any = useSelector;