import {
  Autocomplete,
  Box,
  Grid2,
  IconButton,
  InputAdornment,
  TableCell,
  TableRow,
  TextField,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { tableCellClasses } from "@mui/material/TableCell";

import "./quote-item.css";
import { QuoteItemDto } from "../../store/slicers/quotes.slicer";

import DeleteIcon from "@mui/icons-material/Delete";
import { NumericFormatCustom } from "../shared/NumericFormatCustom";
import { useAppSelector } from "../../store/adminator-store";
import { ArticleDto } from "../../store/slicers/articles.slicer";
import { getMediaUrl } from "../../infrastructure/http-client/http-client";

interface Props {
  quoteItem?: QuoteItemDto;
  showAddButton?: boolean;
  article?: any;
  onAddClicked?: (quoteIte: any) => void;
  onChange?: (quoteItem: QuoteItemDto) => void;
  onRemove(): void;
}

export const QuoteItemComponent = (props: Props) => {
  const [article, setArticle] = useState<any | undefined>(props.article);
  const [description, setDescription] = useState<any | undefined>(
    props.quoteItem?.description ?? props.article?.name
  );
  const [amount, setAmount] = useState<number | undefined>(props.quoteItem?.amount ?? 0);
  const [price, setPrice] = useState<number | undefined>(
    props.quoteItem?.price ?? props.article?.price ?? 0
  );
  const [vat, setVAT] = useState<number | undefined>(props.quoteItem?.vat ?? 0);
  const [total, setTotal] = useState(0);

  const articles: ArticleDto[] = useAppSelector(
    (state) => state.articleState.articles
  );

  const theme = useTheme();

  useEffect(() => {
    const totalPrice = (amount ?? 0) * (price ?? 0);
    setTotal(totalPrice);
  }, [price, amount, vat, article]);

  useEffect(() => {
    if (article) {
      setVAT(article.vat);
      setPrice(article.sellPrice);
    }
  }, [article]);

  useEffect(() => {
    if (props.onChange) {
      const payload = {
        id: props.quoteItem?.id,
        stockItemId: article?.id,
        description: description,
        price: price,
        amount: amount,
        vat: vat,
      };
      props.onChange(payload);
    }
  }, [article, description, price, amount, vat]);

  return (
    <>
      <TableRow>
        <TableCell
          style={{ minWidth: 200, width: "fit-content", whiteSpace: "nowrap" }}
        >
          <Autocomplete
            freeSolo
            disablePortal
            options={articles}
            fullWidth
            size="small"
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.name
            }
            sx={{
              [`& input`]: {
                // fontWeight: "bold",
              },
              minWidth: 300,
            }}
            onInputChange={(_, params) => {
              setDescription(params);
            }}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" />
            )}
            renderOption={(props, option) => {
              const { key, ...optionProps } = props;
              return (
                <Box
                  key={key}
                  component="li"
                  sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                  {...optionProps}
                >

                  {/* <img
                    loading="lazy"
                    width="20"
                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                    alt=""
                  /> */}
                  <img height={70} width={70 * 16 / 9} src={getMediaUrl("article", option.iconUrl)}/>
                  {option.name} 
                </Box>
              );
            }}
            onChange={(e, val: ArticleDto | null) => setArticle(val)}
            value={description}
          />
        </TableCell>
        <TableCell>
          <TextField
            size="small"
            variant="outlined"
            fullWidth
            value={amount}
            onClick={(e) => (e.target as HTMLInputElement)?.select()}
            sx={{
              [`& input`]: {
                textAlign: "right",
              },
            }}
            onChange={(e: any) => {
              setAmount(parseFloat(e.target.value));
            }}
            slotProps={{
              input: {
                inputComponent: NumericFormatCustom as any,
                style: {
                  width: 70,
                },
              },
            }}
          />
        </TableCell>
        <TableCell>
          <TextField
            size="small"
            variant="outlined"
            fullWidth
            value={price}
            sx={{
              [`& input`]: {
                textAlign: "right",
              },
            }}
            onClick={(e) => (e.target as HTMLInputElement)?.select()}
            onChange={(e: any) => {
              setPrice(parseFloat(e.target.value));
            }}
            slotProps={{
              input: {
                inputComponent: NumericFormatCustom as any,
                slotProps: {
                  input: {
                    prefix: "€ ",
                  },
                },
                style: {
                  width: 100,
                },
              },
            }}
          />
        </TableCell>
        <TableCell>
          <TextField
            size="small"
            inputMode="numeric"
            type="number"
            variant="outlined"
            fullWidth
            value={vat}
            onChange={(e: any) => {
              setVAT(parseFloat(e.target.value));
            }}
            onClick={(e) => (e.target as HTMLInputElement)?.select()}
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position="start">%</InputAdornment>
                ),
                style: {
                  width: 100,
                },
              },
            }}
          />
        </TableCell>
        <TableCell>
          <TextField
            value={total}
            variant="outlined"
            fullWidth
            disabled
            size="small"
            sx={{
              [`& input`]: {
                textAlign: "right",
              },
            }}
            slotProps={{
              input: {
                readOnly: true,
                inputComponent: NumericFormatCustom as any,
                slotProps: {
                  input: {
                    prefix: "€ ",
                  },
                },
                style: {
                  resize: "both",
                  minWidth: 150,
                  fontWeight: "normal",
                },
              },
            }}
          />
        </TableCell>
        <TableCell>
          <TextField
            value={total * (1 + (vat ?? 0) / 100)}
            variant="outlined"
            size="small"
            fullWidth
            disabled
            sx={{
              [`& input`]: {
                textAlign: "right",
              },
            }}
            slotProps={{
              input: {
                inputComponent: NumericFormatCustom as any,
                slotProps: {
                  input: {
                    prefix: "€ ",
                  },
                },
                readOnly: true,
                style: {
                  resize: "both",
                  minWidth: 150,
                  fontWeight: "bold",
                },
              },
            }}
          />
        </TableCell>
        <TableCell
          rowSpan={2}
          style={{ display: "flex", justifyContent: "flex-start" }}
        >
          <IconButton
            size="large"
            color="error"
            onClick={() => props.onRemove()}
          >
            <DeleteIcon />
          </IconButton>
        </TableCell>

        {/* {props.showAddButton && (
        <Grid2>
          <IconButton
            onClick={() => (props.onAddClicked ? props.onAddClicked({ article, amount, price }) : null)}
          >
            <AddIcon />
          </IconButton>
        </Grid2>
      )} */}
      </TableRow>
      {/* <TableRow
        sx={{
          [`& .${tableCellClasses.root}`]: {
            paddingLeft: 1,
            paddingRight: 1,
            paddingUp: 0.5,
            paddingDown: 0.5,
          },
        }}
      >
        <TableCell colSpan={7}>
          <Grid2 size="grow">
            <TextField
              label="extra notities"
              variant="outlined"
              fullWidth
              multiline
              rows={2}
            />
          </Grid2>
        </TableCell>
      </TableRow> */}
    </>
  );
};
