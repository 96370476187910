import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createOrUpdateArticle, getArticles, selectCompany, syncProfile } from "../../infrastructure/hub/dashboard-hub";
import { quoteSlicer } from "./quotes.slicer";

export interface ArticleDto {
  id: number;
  name: string;
  buyPrice: number;
  sellPrice: number;
  vat: number;
  iconUrl: string;
  stockItemType: number;
}

interface ArticleState {
  isLoading: boolean;
  isSaving: boolean;
  articles: ArticleDto[];
}

export const fetchArticles = createAsyncThunk(
  "articles/get",
  async (companyId: number) => {
    const response = await getArticles(companyId);
    return response;
  }
);

export const createArticle = createAsyncThunk(
  "articles/create",
  async ({ companyId, article }: { companyId: number; article: ArticleDto }) => {
    return await createOrUpdateArticle(companyId, article);
  }
);


const initialState: ArticleState = {
  isLoading: true,
  isSaving: false,
  articles: [],
};

export const articleSlicer = createSlice({
  name: "counter",
  initialState,
  reducers: {
    addArticleAction: (state, action: PayloadAction<ArticleDto>) => {
      const existingIndex = state.articles.findIndex(
        (x) => x.id == action.payload.id
      );

      let clones = [...state.articles];
      if (existingIndex >= 0) {
        clones[existingIndex] = action.payload;
      } else {
        clones.push(action.payload);
      }
      state.articles = clones;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(fetchArticles.pending, (state) => {
        state.isLoading = true;
        state.articles = [];
      })
      .addCase(fetchArticles.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(
        fetchArticles.fulfilled,
        (state, action: PayloadAction<ArticleDto[]>) => {
          state.articles = action.payload;
          state.isLoading = false;
        }
      )
      .addCase(createArticle.pending, (state) => {
        state.isSaving = true;
      })
      .addCase(createArticle.rejected, (state) => {
        state.isSaving = false;
      })
      .addCase(
        createArticle.fulfilled,
        (state) => {
          state.isSaving = false;
        }
      )
      
});

export const { addArticleAction } = articleSlicer.actions;
