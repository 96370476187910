import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { selectCompany, syncProfile } from "../../infrastructure/hub/dashboard-hub";

export interface CompanyDto {
  id: number;
  name: string;
  vat: string;
  street?: string;
  houseNumber?: string;
  zip?: string;
  city?: string;
  activeSince: Date;
}
export interface UserSettingsDto {
  isLoading: boolean;
  companies: CompanyDto[];
  selectedCompanyId?: number;
  selectedTheme?: string;
}

export const fetchUserProfile = createAsyncThunk(
  "usersettings/get",
  async (payload: {name?: string, profilePicUrl?: string}) => {
    const response = await syncProfile(payload.name, payload.profilePicUrl);
    return response;
  }
);

export const setSelectedUserCompany = createAsyncThunk(
  "usersettings/set-selected-company",
  async (id: number) => {
    await selectCompany(id);
    return id;
  }
);

const initialState: UserSettingsDto = {
  isLoading: true,
  companies: [],
};

export const userSettingsSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setSelectedCompany: (state, action: PayloadAction<number>) => {
      state.selectedCompanyId = action.payload;
    },
    setCompanies: (state, action: PayloadAction<number>) => {
      state.selectedCompanyId = action.payload;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(fetchUserProfile.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchUserProfile.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(
        fetchUserProfile.fulfilled,
        (state, action: PayloadAction<UserSettingsDto>) => {
          state.companies = action.payload.companies;
          state.selectedCompanyId = action.payload.selectedCompanyId;
          state.isLoading = false;
        }
      )
      .addCase(
        setSelectedUserCompany.fulfilled,
        (state, action: PayloadAction<number>) => {
          state.selectedCompanyId = action.payload;
        }
      ),
});
