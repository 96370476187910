import { useEffect } from "react";
import "./App.css";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { RouterProvider } from "react-router-dom";


import { useAuth0 } from "@auth0/auth0-react";
import { connectHub } from "./infrastructure/hub/dashboard-hub";
import { Button, CircularProgress, Grid2, Typography } from "@mui/material";
import { httpClient } from "./infrastructure/http-client/http-client";
import { useAppDispatch, useAppSelector } from "./store/adminator-store";
import { fetchUserProfile } from "./store/slicers/user-settings.slicer";
import { fetchQuotes } from "./store/slicers/quotes.slicer";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { router } from "./routes";
import { fetchArticles } from "./store/slicers/articles.slicer";
const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#476476",
      contrastText: '#FFFFFF'
    },
    secondary: {
      main: "#35393c",
      contrastText: '#FFFFFF'
    },
    success: {
      main: '#136740'
    },
    background: {
      default: '#e0e0de',  // Softer background color for better contrast
    }
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#f3f3f3',
          borderRadius: 12,  
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          paddingLeft: 8,  // Small padding for better spacing
          paddingRight: 8,
          borderRadius: 12,  // Slightly smaller border-radius for subtle softness
        },
      }
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          paddingLeft: 8,  // Small padding for better spacing
          paddingRight: 8,
          backgroundColor: '#f3f3f3',
          borderRadius: 12,  // Slightly smaller border-radius for subtle softness
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          paddingLeft: 8,
          paddingRight: 8,  // Corrected paddingRight key
          paddingTop: 4,    // Added small top padding for better spacing
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          borderRadius: 12,  // Slightly smaller border-radius for subtle softness
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backdropFilter: "blur(2px)",  // Increased blur for a more refined look
          backgroundColor: "rgba(0, 0, 0, 0.3)",  // Softened overlay for a cleaner look
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          backgroundColor: "#f3f3f3",  // Softened overlay for a cleaner look
        }
      }
    }
  },
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    button: {
      textTransform: 'none',  // Disables uppercase text on buttons for a modern look
      fontWeight: 600,        // Slightly bolder font for button emphasis
    },
    h6: {
      fontWeight: 500,        // Bold headings for clarity
    },
  },
});



export const App = () => {
  const {
    getAccessTokenSilently,
    isAuthenticated,
    isLoading,
    user,
    loginWithRedirect
  } = useAuth0();

  const appDispatch = useAppDispatch();
  const userProfile = useAppSelector((state) => state.userSettings);
  const selectedCompanyId = useAppSelector(
    (state) => state.userSettings.selectedCompanyId
  );

  const connectHubs = async () => {
    const token = await getAccessTokenSilently();
    httpClient.setAccessToken(token);
    await connectHub(token);
    await appDispatch(fetchUserProfile({name: user.name, profilePicUrl: user.picture }));
    
    appDispatch(fetchArticles(selectedCompanyId));
    appDispatch(fetchQuotes(selectedCompanyId));
  };

  useEffect(() => {
    if (!isAuthenticated) return;
    connectHubs();
    console.log("Init");
  }, [isAuthenticated]);

  useEffect(() => {
    if (selectedCompanyId) {
      appDispatch(fetchArticles(selectedCompanyId));
      appDispatch(fetchQuotes(selectedCompanyId));
    }
  }, [selectedCompanyId]);

  const renderApp = () => {
    if (!isAuthenticated && !isLoading) {
      return (
        <Grid2
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          height="100vh"
          spacing={1}
        >
          <Grid2>
            <Typography>Jammer, Uw sessie is verlopen.</Typography>
          </Grid2>
          <Grid2>
            <Button variant="contained" onClick={() => loginWithRedirect()}>
              Opnieuw aanmelden
            </Button>
          </Grid2>
        </Grid2>
      );
    }

    if (isLoading || userProfile.isLoading) {
      return (
        <Grid2
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <CssBaseline />
          <Grid2>
            <CircularProgress />
          </Grid2>
          <Grid2>
            <Typography>Profiel laden...</Typography>
          </Grid2>
        </Grid2>
      );
    }

    return <RouterProvider router={router} />;
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {renderApp()}
      </ThemeProvider>
    </LocalizationProvider>
  );
};
