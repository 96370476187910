import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import {
  useNavigate,
  Link as RouterLink,
  ScrollRestoration,
} from "react-router-dom";
import {
  Alert,
  Avatar,
  Breadcrumbs,
  Button,
  FormControl,
  Grid2,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  MenuList,
  Paper,
  Select,
  Snackbar,
  useMediaQuery,
} from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { useAppDispatch, useAppSelector } from "../store/adminator-store";
import {
  CompanyDto,
  setSelectedUserCompany,
} from "../store/slicers/user-settings.slicer";
import { Breadcrumb } from "../store/slicers/breadcrumbs.slicer";
import { CreateCompanyPage } from "../pages/company/create-company.page";

import BackIcon from "@mui/icons-material/ArrowBack";
import * as React from "react";

const drawerWidth = 240;

export interface MenuItem {
  title: string;
  url: string;
  icon: any;
  disabled: boolean;
}

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * Remove this when copying and pasting into your project.
   */
  menuItems: MenuItem[];
  children: any;
  style: any;
}

export const ResponseDrawerComponent = (props: Props) => {
  const navigate = useNavigate();
  const appDispatch = useAppDispatch();
  const isMobile = useMediaQuery("(max-width:800px)");

  //const [mobileOpen, setMobileOpen] = React.useState(true);
  //const [isClosing, setIsClosing] = React.useState(false);
  const { loginWithRedirect, logout, user, isAuthenticated } = useAuth0();
  const breadcrumbs: Breadcrumb[] = useAppSelector(
    (state) => state.breadcrumbs.breadcrumbs
  );
  const userProfile = useAppSelector((state) => state.userSettings);

  const userCompanies = useAppSelector(
    (state) => state.userSettings?.companies
  );
  const selectedUserCompanyId = useAppSelector(
    (state) => state.userSettings?.selectedCompanyId
  );

  const snackbarMessages = useAppSelector((state) => state.snackbar.messages);

  const createCompanyFirst =
    isAuthenticated &&
    !userProfile.isLoading &&
    userProfile.companies.length == 0;

  const setSelectedCompany = (id: number) => {
    appDispatch(setSelectedUserCompany(id));
  };

  //const handleDrawerClose = () => {
  //  setIsClosing(true);
  //  setMobileOpen(false);
  //};

  //const handleDrawerTransitionEnd = () => {
  //  setIsClosing(false);
  //};

  //const handleDrawerToggle = () => {
  //  if (!isClosing) {
  //    setMobileOpen(!mobileOpen);
  //  }
  //};

  const createBreadCrumbs = () => {
    if (breadcrumbs.length == 0) return <div />;

    return (
      <Paper className="blurred">
        <Grid2 container direction="row" spacing={1} alignItems="center" p={1}>
          {breadcrumbs.length > 1 && (
            <IconButton
              onClick={() => navigate(breadcrumbs[breadcrumbs.length - 2].link)}
            >
              <BackIcon />
            </IconButton>
          )}
          <Breadcrumbs aria-label="breadcrumb">
            {breadcrumbs.map((value, index) => {
              const isLast = index === breadcrumbs.length - 1;

              return isLast ? (
                <Typography
                  key={index}
                  sx={{ color: "text.primary" }}
                  color="primary"
                >
                  {value.title}
                </Typography>
              ) : (
                <Link
                  underline="hover"
                  color="inherit"
                  component={RouterLink}
                  to={value.link}
                  key={index}
                >
                  {value.title}
                </Link>
              );
            })}
          </Breadcrumbs>
        </Grid2>
      </Paper>
    );
  };

  const drawer = (
    <div style={props.style} className="blurred">
      <Toolbar style={{ width: "inherit" }}>
        <Grid2
          width="stretch"
          container
          direction="column"
          justifyContent="center"
          spacing={1}
          py={1}
        >
          <Grid2 container justifyContent="center" width="stretch">
            <Avatar
              src={user?.picture}
              alt="fallback"
              imgProps={{ referrerPolicy: "no-referrer" }}
            ></Avatar>
          </Grid2>
          {isAuthenticated && <Grid2>Welkom, {user?.given_name}</Grid2>}
          <Grid2>
            {isAuthenticated ? (
              <Button
                variant="text"
                onClick={() => {
                  logout({
                    logoutParams: { returnTo: window.location.origin },
                  });
                }}
              >
                Logout
              </Button>
            ) : (
              <Button onClick={() => loginWithRedirect()}>Login</Button>
            )}
          </Grid2>
          <Grid2>
            <FormControl fullWidth size="small" style={{ width: "200px" }}>
              <InputLabel>Bedrijf</InputLabel>
              <Select
                value={selectedUserCompanyId}
                onChange={(e) => setSelectedCompany(e.target.value)}
                label="Bedrijf"
                size="small"
              >
                {userCompanies.map((x: CompanyDto) => (
                  <MenuItem key={x.id} value={x.id}>
                    {x.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid2>
        </Grid2>
      </Toolbar>
      <Divider />
      <MenuList component={Grid2} container direction="column">
        {props.menuItems.map((mi, index) => (
          <React.Fragment key={index}>
            <Divider />
            <Grid2>
              <MenuItem
                selected={window.location.pathname.indexOf(mi.url) >= 0}
                onClick={() => navigate(mi.url)}
                disabled={mi.disabled}
              >
                <ListItemIcon>{mi.icon}</ListItemIcon>
                <ListItemText style={{ textAlign: "left" }}>
                  {mi.title}
                </ListItemText>
              </MenuItem>
            </Grid2>
          </React.Fragment>
        ))}
        <Grid2 height="auto"></Grid2>
        <Divider />
        <Grid2 py={1}>Versie 1.0.0</Grid2>
      </MenuList>
    </div>
  );

  if (createCompanyFirst) {
    return <CreateCompanyPage />;
  }

  return (
    <>
      <Grid2 container direction="row" alignItems="flex-start">
        {!isMobile && (
          <Grid2 width={drawerWidth} height="100vh">
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            {/* <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onTransitionEnd={handleDrawerTransitionEnd}
            onClose={handleDrawerClose}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer> */}
            <Drawer variant="permanent" open>
              {drawer}
            </Drawer>
          </Grid2>
        )}
        <Grid2
          p={5}
          // width={`calc(100vw - ${drawerWidth}px)`}
          size="grow"
          width={{
            xs: "100vw",
            md: "80vw",
            lg: "70vw",
          }}
          mx="auto"
        >
          <Grid2
            container
            justifyContent="stretch"
            height="stretch"
            width="stretch"
            direction="row"
            spacing={2}
            // style={{overflow: 'auto'}}
          >
            <Grid2  size={12}>{createBreadCrumbs()}</Grid2>
            <Grid2 height="stretch" width="stretch">
              <ScrollRestoration />
              {props.children}
            </Grid2>
          </Grid2>
          {/* <Toolbar /> */}
        </Grid2>
      </Grid2>
      {snackbarMessages.map((x) => (
        <Snackbar open>
          <Alert severity={x.color} variant="standard" sx={{ width: "100%" }}>
            <Typography color="textPrimary">{x.text}</Typography>
          </Alert>
        </Snackbar>
      ))}
    </>
  );
};
