import { CssBaseline } from "@mui/material";
import { createBrowserRouter } from "react-router-dom";
import { ResponseDrawerComponent } from "./components/responsive-drawer";
import { CreateCompanyPage } from "./pages/company/create-company.page";
import { DashboardPage } from "./pages/dashboard/dashboard.page";
import { QuotePage } from "./pages/quotes/quote.page";
import { QuotesPage } from "./pages/quotes/quotes.page";
import { SettingsPage } from "./pages/settings/settings.page";
import { QuoteIntegrationBuilderPage } from "./pages/quote-integration-builder/quote-integration-builder.page";

import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import DescriptionIcon from "@mui/icons-material/Description";
import PunchClockIcon from "@mui/icons-material/PunchClock";
import SettingsIcon from "@mui/icons-material/Settings";
import InventoryIcon from "@mui/icons-material/Inventory";
import DashboardIcon from "@mui/icons-material/Dashboard";
import WebIcon from '@mui/icons-material/Web';
import { ArticlesPage } from "./pages/articles/articles-page";

const menuItems = [
  {
    title: "Dashboard",
    url: "/dashboard",
    icon: <DashboardIcon color="primary" fontSize="small" />,
    // icon: <img src="/icons/invoice.png" width="30" />,
    disabled: false,
  },
  {
    title: "Offertes",
    url: "/quotes",
    icon: <RequestQuoteIcon color="primary" fontSize="small" />,
    // icon: <img src="/icons/project-management.png" width="30" />,
    disabled: false,
  },
  // {
  //   title: "Klanten",
  //   url: "/end-customers",
  //   // icon: <DashboardIcon  color="primary" fontSize="small" />,
  //   icon: <img src="./icons/project-management.png" width="30" />,
  //   disabled: true
  // },
  {
    title: "Werkplanning",
    url: "/workcontrol",
    icon: <PunchClockIcon color="primary" fontSize="small" />,
    // icon: <img src="/icons/schedule.png" width="30" />,
    disabled: true,
  },
  {
    title: "Facturen",
    url: "/invoices",
    icon: <DescriptionIcon color="primary" fontSize="small" />,
    // icon: <img src="/icons/invoice.png" width="30" />,
    disabled: true,
  },
  {
    title: "Artikels",
    url: "/articles",
    icon: <InventoryIcon color="primary" fontSize="small" />,
    // icon: <img src="/icons/packages.png" width="30" />,
    disabled: false,
  },
  {
    title: "Offerte integraties",
    url: "/quote-integration",
    icon: <WebIcon color="primary" fontSize="small" />,
    // icon: <img src="/icons/settings.png" width="30" />,
    disabled: false,
  },
  {
    title: "Beheer",
    url: "/settings",
    icon: <SettingsIcon color="primary" fontSize="small" />,
    // icon: <img src="/icons/settings.png" width="30" />,
    disabled: false,
  },
];

export const router = createBrowserRouter(
  [
    {
      path: "",
      element: (
        <>
          <CssBaseline />
          <ResponseDrawerComponent
            menuItems={menuItems}
            style={{ width: "inherit", height: "inherit" }}
          >
            <DashboardPage />
          </ResponseDrawerComponent>
        </>
      ),
    },
    {
      path: "/company/register",
      element: (
        <>
          <CssBaseline />
          <CreateCompanyPage />
        </>
      ),
    },
    {
      path: "/dashboard",
      element: (
        <>
          <CssBaseline />
          <ResponseDrawerComponent
            menuItems={menuItems}
            style={{ width: "inherit", height: "inherit" }}
          >
            <DashboardPage />
          </ResponseDrawerComponent>
        </>
      ),
    },
    {
      path: "/quotes",
      element: (
        <>
          <CssBaseline />
          <ResponseDrawerComponent
            menuItems={menuItems}
            style={{ width: "inherit", height: "inherit" }}
          >
            <QuotesPage />
          </ResponseDrawerComponent>
        </>
      ),
    },
    {
      path: "/quotes/edit-quote/:id?",
      element: (
        <>
          <CssBaseline />
          <ResponseDrawerComponent
            menuItems={menuItems}
            style={{ width: "inherit", height: "inherit" }}
          >
            <QuotePage />
          </ResponseDrawerComponent>
        </>
      ),
    },
    {
        path: 'articles',
        element: (
            <>
              <CssBaseline />
              <ResponseDrawerComponent
                menuItems={menuItems}
                style={{ width: "inherit", height: "inherit" }}
              >
                <ArticlesPage />
              </ResponseDrawerComponent>
            </>
          ),
    },
    {
      path: "quote-integration",
      element: (
        <>
          <CssBaseline />
          <ResponseDrawerComponent
            menuItems={menuItems}
            style={{ width: "inherit", height: "inherit" }}
          >
            <QuoteIntegrationBuilderPage />
          </ResponseDrawerComponent>
        </>
      ),
    },
    {
      path: "/settings",
      element: (
        <>
          <CssBaseline />
          <ResponseDrawerComponent
            menuItems={menuItems}
            style={{ width: "inherit", height: "inherit" }}
          >
            <SettingsPage />
          </ResponseDrawerComponent>
        </>
      ),
    },
  ],
  {
    // Provide the base path for your app if it is hosted on a subpath (e.g., /subpath)
    // basename: process.env.PUBLIC_URL || "/",
  }
);
