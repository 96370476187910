import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface SnackbarMessage {
  message: string;
  color: string;
}

export interface SnackbarStore {
  messages: SnackbarMessage[];
}

const initialState: SnackbarStore = {
  messages: []
};

export const snackbackSlice = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    addSnackbarMessage: (state, action: PayloadAction<SnackbarMessage>) => {
      state.messages = [action.payload, ...state.messages];
    },
  },
});

export const { addSnackbarMessage } = snackbackSlice.actions;
