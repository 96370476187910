import {
  Box,
  Button,
  CircularProgress,
  Grid2,
  IconButton,
  ListItem,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DataGrid, gridClasses, GridColDef } from "@mui/x-data-grid";
import { useAppDispatch, useAppSelector } from "../../store/adminator-store";
import {
  createQuoteAction,
  getTotalQuoteValueExclVat,
  QuoteDto,
} from "../../store/slicers/quotes.slicer";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import RemoveIcon from "@mui/icons-material/Delete";
import { useEffect } from "react";
import { setBreadcrumbs } from "../../store/slicers/breadcrumbs.slicer";
import { invoiceCrumbs } from "../breadcrumbs";
import * as moment from "moment";

export const QuotesPage = () => {
  const navigate = useNavigate();
  const appDispatch = useAppDispatch();

  const isLoading: boolean = useAppSelector((state) => state.quotes.isLoading);
  const quotes: QuoteDto[] = useAppSelector((state) => state.quotes.quotes);

  const selectedCompanyId = useAppSelector(
    (state) => state.userSettings.selectedCompanyId
  );

  const creatingQuote: boolean = useAppSelector(
    (state) => state.quotes.isSaving
  );

  useEffect(() => {
    appDispatch(setBreadcrumbs(invoiceCrumbs()));
  }, []);

  useEffect(() => {
    if (!selectedCompanyId) return;
    // appDispatch(fetchQuotes(selectedCompanyId));
  }, [selectedCompanyId]);

  const createQuote = async () => {
    const result = await appDispatch(
      createQuoteAction({
        companyId: selectedCompanyId,
        quote: {
          title: `Nieuwe offerte ${moment().format("MMMM Do YYYY, h:mm:ss a")}`,
          quoteNr: quotes.length.toString().padStart(7, "0"),
          lines: [],
          endCustomer: {},
          expiredInDays: 30,
          messages: [],
        },
      })
    );

    navigate(`/quotes/edit-quote/${result.payload}`);
  };

  const columns: GridColDef<(typeof quotes)[number]>[] = [
    { field: "quoteNr", headerName: "Offerte Nr", flex: 1 },
    // {
    //   field: "title",
    //   headerName: "Titel",
    //   editable: false,
    //   flex: 1,
    //   renderCell: (params) => (
    //     <ListItem disablePadding>
    //       <ListItemText
    //         primary={params.value}
    //         // secondary={params.row.endCustomer?.name}
    //       ></ListItemText>
    //     </ListItem>
    //   ),
    // },
    {
      field: "totalPrice",
      headerName: "Totaalprijs (excl. BTW)",
      flex: 1,
      renderCell: (params) => {
        return (
          <Typography textAlign="right">
            €{" "}
            {getTotalQuoteValueExclVat(params.row as QuoteDto).toLocaleString()}
          </Typography>
        );
      },
      align: "right",
      // align: "right",
      headerAlign: "right",
    },
    {
      field: "customer",
      headerName: "Klant",
      flex: 1,
      renderCell: (params) => (
        <ListItem disablePadding>
          <ListItemText
            primary={params.row.endCustomer?.name}
            secondary={
              params.row.endCustomer?.address
                ? `${params.row.endCustomer?.address}, ${params.row.endCustomer?.zip} ${params.row.endCustomer?.city}`
                : ""
            }
          ></ListItemText>
        </ListItem>
      ),
    },
    {
      field: "updatedDate",
      headerName: "Gewijzigd",
      flex: 1,
      type: "dateTime",
      valueGetter: (val) => new Date(val),
      sortable: true,
    },
    {
      field: "createdDate",
      headerName: "Aangemaakt",
      flex: 1,
      type: "dateTime",
      valueGetter: (val) => new Date(val),
    },
    {
      field: "status",
      headerName: "Status",
      align: "center",
      headerAlign: "center",
      flex: 1,
      renderCell: (params) => {
        if (params.value == 0) return "In opmaak";
        if (params.value == 20) return "Wachten op klant";
        if (params.value == 50) return "Goedgekeurd door klant";
        if (params.value == 90) return "Geweigerd door klant";
      },
    },
    {
      field: "edit",
      headerName: "",
      width: 60,
      align: "center",
      renderCell: (params) => (
        <IconButton
          color="primary"
          onClick={() => navigate(`/quotes/edit-quote/${params.row.id}`)}
        >
          <EditIcon />
        </IconButton>
      ),
    },
    {
      field: "delete",
      headerName: "",
      width: 60,
      align: "center",
      renderCell: (params) => (
        <IconButton
          color="error"
          onClick={() => navigate(`/quotes/edit-quote/${params.row.id}`)}
        >
          <RemoveIcon />
        </IconButton>
      ),
    },
  ];

  return (
    <Grid2 container direction="column" spacing={2}>
      <Grid2 className="entity-details-header">
        <Paper className="blurred">
          <Grid2 container justifyContent="space-between" alignItems="center" p={2} >
            <Grid2>
              <Typography variant="h5">Uw Offertes</Typography>
            </Grid2>
            <Grid2>
              <Button
                color="primary"
                variant="contained"
                onClick={() => createQuote()}
                startIcon={
                  creatingQuote ? <CircularProgress size={20} /> : <AddIcon />
                }
                disabled={creatingQuote}
              >
                Nieuwe offerte
              </Button>
            </Grid2>
          </Grid2>
        </Paper>
      </Grid2>
      <Grid2>
        <Paper>
          <Box sx={{ display: "grid", gridTemplateColumns: "1fr" }} p={1}>
            <DataGrid
              rows={quotes}
              columns={columns}
              // getRowHeight={() => "auto"}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 50,
                  },
                },
              }}
              pageSizeOptions={[50]}
              checkboxSelection
              sx={{
                [`& .${gridClasses.cell}`]: {
                  display: "flex",
                  alignItems: "center",
                },
              }}
              loading={isLoading}
              slotProps={{
                loadingOverlay: {
                  variant: "skeleton",
                  noRowsVariant: "skeleton",
                },
              }}
            />
          </Box>
        </Paper>
      </Grid2>
    </Grid2>
  );
};
