import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface Breadcrumb {
  title: string;
  link: string;
}

export interface BreadcrumbsStore {
  breadcrumbs: Breadcrumb[];
}

const initialState: BreadcrumbsStore = {
  breadcrumbs: []
};

export const BreadcrumbsSlicer = createSlice({
  name: "breadcrumbs",
  initialState,
  reducers: {
    setBreadcrumbs: (state, action: PayloadAction<Breadcrumb[]>) => {
      state.breadcrumbs = action.payload;
    },
  },
});

export const { setBreadcrumbs } = BreadcrumbsSlicer.actions;
